import React from 'react'
import {graphql} from 'gatsby'
import Link from 'gatsby-plugin-transition-link'
import get from 'lodash/get'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import GatsbyImage from "gatsby-image";
import styles from './blog.module.css'
import Item from "../components/Item";

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    const r1 = [], r2 = [], r3 = [];

    const getColumnHeight = list => {
      let result = 0;

      list.forEach(item => {
        result = result + (1 / item.node.heroImage.fluid.aspectRatio);
      })

      return result || 0;
    };

    const getShortest = () => {
      const ro1 = getColumnHeight(r1);
      const ro2 = getColumnHeight(r2);
      const ro3 = getColumnHeight(r3);

      if (ro1 <= ro2 && ro1 <= ro3) {
        return r1;
      }
      if (ro2 <= ro1 && ro2 <= ro3) {
        return r2
      }
      if (ro3 <= ro1 && ro3 <= ro2) {
        return r3;
      }
      return r1;
    }

    posts.forEach((post) => {
      const shortest = getShortest()
      shortest.push(post)
    })

    const Pic = (node) => <Item>
      <Link
          exit={{
            length: 0.5,
          }}
          entry={{
            delay: 0.5,
            length: 0.0,
          }}
          to={`/${node.slug}`}>
        <GatsbyImage fluid={node.heroImage.fluid} />
      </Link>
    </Item>

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
        <div className={styles.grid}>
          {[r1, r2, r3].map(row =>
              <div className={styles.row}>
                {row.map((h) => Pic(h.node))}
              </div>
          )}
          </div>
        <Item />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: ASC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid (maxWidth: 300) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
