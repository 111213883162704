import React, {useState} from 'react'

import styles from './item.module.css'

const Item = ({children}) => {
    const [rate, setRate] = useState(0);

    const mm = (e) => {
        let x =  ((e.clientX - e.target.getBoundingClientRect().left) / e.target.clientWidth) * 2;
        x = x > 1 ? (1 - (x - 1)) : x;
        let y =  ((e.clientY - e.target.getBoundingClientRect().top) / e.target.clientHeight) * 2;
        y = y > 1 ? (1 - (y - 1)) : y;

        setRate((x*0.5) * (y*0.5))
    }

    const scale = 1 + (rate * .2);

    return (
        <div className={styles.item + " item"} onMouseMove={mm} onMouseLeave={() => setRate(0)}>
            <div className={styles.pic} style={{width: "100%", height: "100%", transform: `scale(${scale})`}}>
                {children}
            </div>
            <div className={styles.shadow + " " + ((rate < 0.01) && styles.hidden)} style={{transform: `translateY(${rate * 300}px)`, filter: `blur(${rate * 80}px)`}} />
        </div>
    )
}

export default Item;